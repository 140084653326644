* {
	line-height: 1.5em;
}

/*ICONS STYLES----------------------------------------------------------*/

.secondaryIcon {
	color: #e0202a !important;
}

.primaryIcon {
	color: #002D58 !important;
}

.cerrarIconButton:hover {
	color: #e0202a !important;
}

/*SCROLLBAR STYLES----------------------------------------------------------*/
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	/* Ancho del scrollbar */
	scrollbar-width: thin;
	scrollbar-color: #888 #f1f1f1;
	scrollbar-color: #555 #f1f1f1;
}

/* Estilo del scrollbar */
::-webkit-scrollbar-track {
	background-color: #f1f1f1;
	/* Color del fondo del scrollbar */
}

::-webkit-scrollbar-thumb {
	background-color: #002D58;
	/* Color de la barra del scrollbar */
}

/* Hover en el scrollbar */
::-webkit-scrollbar-thumb:hover {
	background-color: #555;
	/* Color de la barra del scrollbar al pasar el cursor */
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

.myNav {
	width: 100%;
}

.Box-InfoStepper-Step-Carga {
	z-index: 1;
}

#cargaModalCerrarButton {
	position: 'absolute';
	bottom: '20px';
	left: '20px'
}

#cargaModalAgregarButton {
	position: 'absolute';
	bottom: '20px';
	right: '20px'
}

#modalidad_pago {
	z-index: 2;
}

#tipo_bultos,
#nombreCorto {
	z-index: 2;
}

#paises {
	z-index: 2;
}

#ciudad {
	z-index: 2;
}

#buscar_Booking {
	z-index: 8;
}

#servicio {
	z-index: 2;
}

#tel_lada_embarcador {
	z-index: 2;
}

#productoSelect {
	z-index: 4;
}

#tel_lada_consignatario {
	z-index: 2;
}

#tipoDocumento {
	z-index: 2;
}

#tel_lada_notify {
	z-index: 2;
}

#uiCodProducto {
	z-index: 2;
}

#uiCodEmbalaje {
	z-index: 4;
}

.Datos-bookings {
	font-size: 13px;
	color: var(--text-primary);
	font-family: "Source Sans Pro",
		sans-serif;

}

.Checkbox-Text {
	font-size: 0.8rem;
	color: #000;
	font-family: "Source Sans Pro",
		sans-serif;
}

.Checkbox-Size {
	transform: scale(0.8)
}

.Datos-bookings-titulos {
	font-size: 14px;
	font-weight: bold;
	margin-right: 10px;
	color: var(--text-primary);
	font-family: "Source Sans Pro",
		sans-serif;
}

.Datos-bookings-informacion {
	font-size: 12.5px;
	font-weight: bolder;
	margin-right: 10px;
	font-family: "Source Sans Pro",
		sans-serif;
}

.Consignatario-Card {
	font-size: 13px;
	font-weight: bolder;
	margin-right: 10px;
	color: #000
}

.Consignatario-Card-Title {
	font-size: 13px;
	font-weight: bolder;
	margin-right: 10px;
	color: #000
}

.Consignatario-Card-Info {
	font-size: 13px;
	color: #000;
}

.MuiGrid-root .MuiGrid-item .MuiSvgIcon-root .MuiSvgIcon-fontSizeLarge {
	color: #002D58 !important;
}

.MuiDataGrid-cell {
	font-size: '12px' !important;
	font-family: 'Source Sans Pro' !important;
}

.css-cvrjl1-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-cvrjl1-MuiDataGrid-root .MuiDataGrid-cell:focus {
	outline: none;
}

.MuiStepLabel-iconContainer .Mui-active {
	color: #e0202a !important;
}

.MuiStepLabel-iconContainer .Mui-active .Mui-completed {
	color: #e0202a;
}

.MuiStepLabel-iconContainer .Mui-completed {
	color: #e0202a;
}

.MuiStepLabel-labelContainer .Mui-active .Mui-completed {
	color: #9b9b9b !important;
}

.MuiStepLabel-labelContainer .Mui-completed {
	color: #9b9b9b !important;
}

.MuiStepLabel-labelContainer .Mui-active {
	color: #000000 !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
	color: #002D58 !important;
}

.css-tjaq38-MuiPaper-root-MuiCard-root {
	color: #002D58 !important;
}

.css-MuiPaper-root-MuiCard-root {
	color: #002D58 !important;
}

.css-2ulfj5-MuiTypography-root {
	color: #000;
}

.arrow {
	width: 0;
	height: 0;
	margin: 50px auto;
	animation: moveUpDown 1s infinite ease-in-out;
}

@keyframes moveUpDown {

	0%,
	100% {
		transform: translateX(3px);
	}

	50% {
		transform: translateX(-3px);
	}
}

.Box-title {
	display: flex !important;
	justify-content: space-between !important;
	background-color: var(--background-secondary) !important;
	padding-left: 1px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
}

.Box-tabs-title {
	display: flex !important;
	justify-content: space-between !important;
	background-color: var(--background-secondary) !important;
	padding-left: 1px !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	margin-bottom: 0.5px !important;
}

#boxMainContent {
	width: 100%;
	height: 100%;
	padding: 7px 10px;
}

.stepButtonActive {
	background: #002D58;
	color: white;
}


/*Pantallas xs y s*/
@media only screen and (max-width: 599px) {
	.grid-container-sub {
		padding-right: 2%;
		margin-right: 6%;
	}

	.h1-Bill-of-Lading {
		padding-left: 2%;
	}

	.Box-Form-BLInfoStepper {
		height: 100%;
	}

	.Grid-BLMySnackBar {
		padding-top: 3%;
	}

	.BLInfoStepper-Steplabel {
		width: 60px;
	}

	.ListaCargas {
		width: 350 !important;
		display: grid;
		overflow-x: scroll !important;
	}

	#stackHomeCard {
		width: 100%;
	}
}

@media only screen and (min-width: 1200px) {
	#Box-Minidrawer {
		display: flex;
		height: "100%";
	}
}

@media only screen and (min-width: 600px) {
	.Box-Form-BLInfoStepper {
		height: 200px;
		padding-right: 0 !important;
	}

	.Box-MyInfoStepper {
		height: 100px;
	}

	.Div-InfoStepper {
		height: 20vh;
	}
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
	.grid-container-sub {
		padding-left: 3%;
		padding-right: 2%;
	}
}

/* == Flip icon ==================================================*/
.flip-icon {
	animation: flip-animation 1s ease-in-out;
	transform-origin: center;
}

@keyframes flip-animation {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(180deg);
	}
}

/* === Cards =================================================*/
.importantCard {
	background-color: #dfe8f7;
	margin: 2%;
	padding: 6%;
	border-radius: 5px;
	margin: 0;
	display: flex !important;
	align-items: stretch !important;
}

.importantCard .myCardTitle {
	color: #4483d9;
	font-weight: 500;
}

.importantCard .myCardNum {
	color: #4483d9;
	font-weight: 700;
	font-size: 1.6em;
}

.basicCard {
	background-color: #f0f2f5;
	margin: 2%;
	padding: 4%;
	border-radius: 5px;
	margin: 0;
	display: flex !important;
	align-items: stretch !important;
}

.basicCard .myCardTitle {
	font-weight: 500;
}

.basicCard .myCardNum {
	font-weight: 600;
	font-size: 1.6em;
}

.myCardContentRow {
	margin-top: 10%;
}

.myCardIcon {
	font-size: 2em !important;
	float: right;
}

.listButton::hover {
	background-color: #ddd5d538;
}

.myCardTime {
	background-color: #4483d9 !important;
	color: #fff !important;
	float: right;
}

.defaultCard {
	text-align: center;
	background-color: #f0f2f5;
	padding: 6%;
	border-radius: 5px;
}

.defaultCardProfileUser {
	text-align: center;
	background: linear-gradient(180deg, #4482d95e 10%, #ddd5d538 90%);
	padding: 6%;
	border-radius: 5px;
	box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
		0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
	justify-content: center;
	align-items: center;
	margin-right: 2%;
	height: 440px;
	display: grid;
}

.defaultCardProfileClient {
	text-align: center;
	background: linear-gradient(180deg, #4482d95e 10%, #ddd5d538 90%);
	padding: 6%;
	border-radius: 5px;
	box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
		0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
	justify-content: space-between;
	height: 440px;
}

.default-Card-Fotos {
	justify-content: space-between;
	max-width: 400px;
	max-height: 500px;
	width: auto;
	height: auto;
}

.catalogos-card {
	width: 100%;
}

@media (max-width:900px) {
	.catalogos-card {
		width: 50%;
	}
}

.Box-perfil-Aligner {

	display: flex;
	align-items: center;
	padding-top: 3%;
	justify-content: center;
}

.iconUser {
	background-color: #4483d9 !important;
	height: 40px !important;
	width: 40px !important;
	font-size: 20px !important;
}

.iconInfo {
	background-color: #4483d9 !important;
	width: 25px !important;
	height: 25px !important;
	font-size: 7px !important;
}

.iconClient {
	background-color: #4483d9 !important;
	height: 40px !important;
	font-size: 28px !important;
}

.navBarAvatar {
	height: 35px !important;
	width: 35px !important;
	font-size: 16px !important;
	font-family: "Open Sans",
		sans-serif !important;
	color: #fff !important;
}

.text-center,
.center {
	text-align: -webkit-center;
}

.height-100 {
	height: 100vh;
}

.display-flex {
	display: flex;
}

.overflow-y {
	overflow-y: scroll;
	height: 580px;
}

.my-label-select {
	z-index: 100 !important;
	background-color: #fff;
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.my-select-width {
	width: 100% !important;
}

.emptyStyles .select__control * {
	background-color: #e0202a5b;
}

.emptySelect * {
	background-color: #e0202a54;
}

.css-10hburv-MuiTypography-root {
	font-size: 0.91rem !important;
	line-height: 1.3 !important;
}

.MuiTab-root {
	padding-left: 0 !important;
}

nav[aria-label="pagination navigation"] {
	background-color: #fff;
}


.fac-card {
	width: 100% !important;
	padding: 1em !important;
	margin-top: 1em !important;
}

.fac-card-top {
	width: 100% !important;
	padding-left: 1em !important;
	padding-right: 1em !important;

}

.fac-conceptos-title {
	background: #002D58 !important;
	border-top: 1px solid #ccc;
	color: white;
}

.fac-conceptos-item {
	padding: 0.6em 0.6em 0.6em 1em;
	border-bottom: 1px solid #ffffff;
	background-color: #ffffff;
}


.border-top-gris {
	border-top: 1px solid lightgray;
}


/*=== Margin ====================================================*/
.margin-0-auto {
	margin: 0 auto !important;
}

.margin-1-0 {
	margin: 1rem 0 !important;
}

.margin-bottom-1 {
	margin-bottom: 1rem !important;
}

.margin-bottom-16 {
	margin-bottom: 16px;
}

.margin-bottom-29 {
	margin-bottom: 29px !important;
}

.margin-top-1em {
	margin-top: 1em !important;
}

.margin-top-1-7em {
	margin-top: 1.7em !important;
}

/*=== Padding ====================================================*/
.padding-right-0 {
	padding-right: 0;
}

.padding-right-3 {
	padding-right: 3%;
}

.padding-left-0 {
	padding-left: 0 !important;
}

.padding-left-2 {
	padding-left: 2%;
}

.padding-upanddown {
	padding-bottom: 2%;
	padding-top: 2%;
}

.padding-bottom-6 {
	padding-bottom: 6%;
}

.padding-top-3 {
	padding-top: 3%;
}

.padding-top-6 {
	padding-top: 6%;
}

.padding-top-8 {
	padding-top: 8%;
}

.padding-top-14 {
	padding-top: 14%;
}

.padding-profilcard {
	padding-left: 2%;
	padding-bottom: 2%;
	padding-top: 2%;
}

.padding-2 {
	padding: 2%;
}

.paper-hover:hover {
	box-shadow: 0px 8px 4px -1px rgba(0, 0, 0, 0.2),
		0px 3px 4px 0px rgba(0, 0, 0, 0.14),
		0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

/*** Grid *************************************************************/
.Grid {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}

.Grid-cell {
	flex: 1;
}

.Grid--flexCells>.Grid-cell {
	display: flex;
}

.Grid--top {
	align-items: flex-start;
}

.Grid--bottom {
	align-items: flex-end;
}

.Grid--stretch {
	align-items: stretch;
}

.flex-stretch {
	display: flex !important;
	align-items: stretch !important;
}

.Grid--normal {
	align-items: normal;
	justify-content: normal;
}

.Grid--bottom {
	align-items: flex-end;
}

.Grid--justifyCenter {
	justify-content: center;
}

.Grid--justifySpaceEvenly {
	justify-content: space-evenly;
}

.Grid-cell--top {
	align-self: flex-start;
}

.Grid-cell--bottom {
	align-self: flex-end;
}

.Grid-cell--center {
	align-self: center;
}

.Grid-cell--autoSize {
	flex: none;
}

.Grid--fit>.Grid-cell {
	flex: 1;
}

.Grid--full>.Grid-cell {
	flex: 0 0 100%;
}

.Grid--1of2>.Grid-cell {
	flex: 0 0 50%;
}

.Grid--1of3>.Grid-cell {
	flex: 0 0 33.3333%;
}

.Grid--1of4>.Grid-cell {
	flex: 0 0 25%;
}

/********VERTICAL ALIGNER****************************************/
.Aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.Aligner--grid {
	display: grid;
	align-items: center;
	justify-content: center;
}

.Aligner-left {
	display: flex;
	align-items: center;
	justify-content: left;
	flex-direction: column;
}

.Aligner-item {
	flex: 1;
}

.Aligner-item--top {
	align-self: flex-start;
}

.Aligner-item--bottom {
	align-self: flex-end;
}

tas .Aligner-item--fixed {
	flex: none;
	max-width: 50%;
}

/********MINI DRAWER****************************************/
#Menu {
	background-color: var(--background-secondary);
	background: var(--background-secondary);
}

#Menu-text {
	color: #002D58;
}

#Menu-icon {
	color: #002D58;
	font-size: "small";
}

#Menu-collapse-list {
	color: #002D58;
	font-size: "small";
}

/************************************************************/

.secondColor {
	background: #e0202a;
}

/********TIMELINE STYLE****************************************/
.timelineSucces label {
	background-color: #e73333;
}

#Timeline-content {
	font-size: small;
}

/*********BREADCRUMBS*******************************************/
.breadcrumbs {
	max-width: 1200px;

}

.breadcrumbs>* {
	display: inline-flex;
	margin-right: 10px;
}

.breadcrumbs .crumb:after {
	content: '>';
	margin-left: 10px;
	font-size: 14px;
}

.breadcrumbs .crumb:last-child:after {
	display: none;
}

.breadcrumbs .crumb a {
	color: rgb(255, 255, 255);
}

/* Underline From Right */
.hvr-underline-from-right {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-from-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 100%;
	right: 0;
	bottom: 0;
	background: #002d58;
	height: 4px;
	-webkit-transition-property: left;
	transition-property: left;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-from-right:hover:before,
.hvr-underline-from-right:focus:before,
.hvr-underline-from-right:active:before {
	left: 0;
}

.helpCenterCard {
	border-radius: 5px;
	margin: 8px;
	margin-bottom: 1em !important;
	margin-left: 8px !important;
	padding: 2%;
}

#selectRazonSocial {
	min-width: 200px;
}

#selectRazonSocial div div div {
	color: #fff;
}

#selectRazonSocial div div span {
	display: none;
}